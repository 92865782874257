import GeoSearch from "./pages/GeoSearch.jsx";
import SaasImage from "./images/Saas.png";
import ECommImage from "./images/E-commerce.png";
import GeoImage from "./images/Geo.png";
import ChartsImage from "./images/Charts_Dashboard.png";
import SemanticSearchImage from "./images/Semantic_Search.png";
import AIImage from "./images/AI_Q&A.png";
import ECommerce from "./pages/ECommerce/index.jsx";
import KNN from "./pages/KNN/index.jsx";
import AI_Q_n_A from "./pages/AI_Q_n_A/index.jsx";
import KNNWithFaceting from "./pages/KNN/knn-with-faceting.jsx";

export const cardsData = [
	{
		title: "KNN Search",
		route: "/knn",
		bgImage: SemanticSearchImage,
		component: KNN,
		breadcrumb: "KNN Search",
	},
	{
		title: "KNN Search with Faceting",
		route: "/knn-with-faceting",
		bgImage: SemanticSearchImage,
		component: KNNWithFaceting,
		breadcrumb: "KNN Search with Faceting",
	},
	{
		title: "E-commerce Search",
		route: "/e-commerce",
		bgImage: ECommImage,
		component: ECommerce,
		breadcrumb: "E-commerce",
	},
	{
		title: "Geo Search",
		route: "/geo-search",
		bgImage: GeoImage,
		component: GeoSearch,
		breadcrumb: "Geo Search",
	},
	{
		title: "AI Q&A",
		route: "/ai-q-n-a",
		bgImage: AIImage,
		component: AI_Q_n_A,
		breadcrumb: "AI Q&A",
	},
	{
		title: "SaaS Search ↗️",
		//https://codesandbox.io/p/sandbox/faceted-template-forked-dcjv3y
		link: "https://dcjv3y.csb.app/",
		bgImage: SaasImage,
	},
	{
		title: "Charts Dashboard ↗️",
		link: "https://charts-dashboard-app.reactiveapps.io/",
		bgImage: ChartsImage,
	},
];
