import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { FaUsers, FaBuilding } from 'react-icons/fa';
import axios from 'axios';

// Styled components
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const ResultList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ResultItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 1rem;
`;

const Info = styled.div`
  flex: 1;
`;

const CompanyName = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`;

const OneLiner = styled.p`
  margin: 0.5rem 0;
`;

const Tags = styled.div`
  margin: 0.5rem 0;
`;

const Tag = styled.span`
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
`;

const MetaItem = styled.span`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
`;

const Link = styled.a`
  color: #007BFF;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// Main KNN component
const KNN = () => {
  const [searchInput, setSearchInput] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchTime, setSearchTime] = useState(null);
  const inputRef = useRef(null);

  const randomQueries = [
    'Semantic search API',
    'AI-based company',
    'Machine learning service',
    'Data analytics platform',
    'Startup search tool'
  ];

  const handleSearch = async (query) => {
    setLoading(true);
    setNoResults(false);
    setResults([]);
    
    const startTime = performance.now();

    try {
      const response = await axios.post(
        'https://appbase-demo-ansible-abxiydt-arc.searchbase.io/_knn_search/_reactivesearch',
        {
          query: [
            {
              id: 'search',
              value: query,
              vectorDataField: 'vector_data'
            }
          ]
        },
        {
          auth: {
            username: 'a03a1cb71321',
            password: '75b6603d-9456-4a5a-af6b-a487b309eb61'
          }
        }
      );

      const hits = response.data.hits?.hits || [];
      setResults(hits);

      if (hits.length === 0) {
        setNoResults(true);
      }

      setSearchTime(performance.now() - startTime);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRandomSearch = () => {
    const randomQuery = randomQueries[Math.floor(Math.random() * randomQueries.length)];
    setSearchInput(randomQuery);
    handleSearch(randomQuery);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchInput);
  };

  useEffect(() => {
    // Debounce search input changes
    const debounceTimeout = setTimeout(() => {
      if (searchInput) {
        handleSearch(searchInput);
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchInput]);

  return (
    <Container>
      <h2>K-Nearest Neighbors Search</h2>
      <form onSubmit={handleSubmit}>
        <SearchInput
          ref={inputRef}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search for a company or service..."
        />
        <Button type="submit">Search</Button>
        <Button type="button" onClick={handleRandomSearch}>
          Try random search input
        </Button>
      </form>

      {loading && <p>Loading results...</p>}
      {noResults && <p>No results found.</p>}
      {searchTime && <p>Search took {searchTime.toFixed(2)} ms</p>}

      <ResultList>
        {results.map((result) => {
          const { _source: company } = result;
          return (
            <ResultItem key={company.name}>
              <Logo src={company.small_logo_thumb_url} alt={`${company.name} logo`} />
              <Info>
                <CompanyName>{company.name}</CompanyName>
                <OneLiner>{company.one_liner}</OneLiner>
                <Meta>
                  <MetaItem><FaUsers /> {company.team_size}</MetaItem>
                  <MetaItem><FaBuilding /> {company.stage}</MetaItem>
                </Meta>
                <Tags>
                  {company.industries.map((industry) => (
                    <Tag key={industry}>{industry}</Tag>
                  ))}
                </Tags>
                <Link href={company.website} target="_blank" rel="noopener noreferrer">
                  Visit website
                </Link>
              </Info>
            </ResultItem>
          );
        })}
      </ResultList>
    </Container>
  );
};

export default KNN;